import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { ROUTES } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { errorInterceptor } from './core/interceptors/error.interceptor';
import { jwtInterceptor } from './core/interceptors/jwt.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ToastComponent } from './shared/components/toast/toast.component';
import { provideToastr } from 'ngx-toastr';

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(ROUTES),
    provideHttpClient(withInterceptors([jwtInterceptor, errorInterceptor])), 
    provideAnimationsAsync(),
    provideToastr({ positionClass: 'toast-top-right', toastComponent: ToastComponent, timeOut: 4000 })
  ]
};
