import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { CookieService } from '../services/cookie.service';
import { FlatDetailsLayoutService } from '../../layouts/dashboard/routes/flat-details/services/flat-details-layout.service';

export const jwtInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next) => {
    const authenticationService = inject(AuthenticationService);
    const flatDetailsLayoutService = inject(FlatDetailsLayoutService);
    const cookieService = inject(CookieService);

    const token = cookieService.getCookie('token');

    if (authenticationService?.currentUser?.value) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
                ownership: flatDetailsLayoutService.currentFlat?.value?.ownership ?? '' // ownershipSeq
            }
        });
    }

    return next(request);
};