import { HttpErrorResponse, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { RESPONSE_CODES } from '../../shared/models/enum';
import { AuthenticationService } from '../services/authentication.service';
import { inject } from '@angular/core';
import { TOAST_TYPE, ToastService } from '../../shared/components/toast/toast.service';

export const errorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next) => {
    const authenticationService = inject(AuthenticationService);
    const toastService = inject(ToastService);

    return next(req).pipe(
        catchError((err: HttpErrorResponse) => {
            // TODO: Update the below code - To handle all errors.

            if (err instanceof HttpErrorResponse && navigator.onLine) {
                console.error(err.message);

                switch (err.status) {
                    // Sessions Expired or Unauthorized.
                    case RESPONSE_CODES.SESSION_EXPIRED:
                    case RESPONSE_CODES.UNAUTHORIZED:
                        toastService.showToast('ph-fill ph-warning', 'Session Expired!', TOAST_TYPE.ERROR);
                        authenticationService.logOutUser();
                        break;

                    default:
                        toastService.showToast('ph-fill ph-warning', 'Internal Server Error!', TOAST_TYPE.ERROR);
                        break;
                }
            }
            
            return throwError(() => err);
        })
    );
}