import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { inject } from '@angular/core';

export const loggedInGuard: CanActivateFn = () => {
    const authenticationService = inject(AuthenticationService);
    const router = inject(Router);

    if (authenticationService.currentUser.value?.contactNumber || authenticationService.currentUser.value?.customerID) {
        router.navigate(['/'])
        return false;
    } else {
        return true;
    }
};