import { Component, HostListener, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent {

	isOnline = signal<boolean>(navigator.onLine);

	@HostListener('window:online')
	onOnline() {
		this.isOnline.set(true);
		this.reloadApp();
	}

	@HostListener('window:offline')
	onOffline() {
		this.isOnline.set(false);
	}

	reloadApp() {
		location.reload();
	}
}
