import { Routes } from '@angular/router';
import { loggedInGuard } from './core/guards/logged-in.guard';
import { authGuard } from './core/guards/auth.guard';

export const ROUTES: Routes = [
    { 
        path: '',
        canActivate: [authGuard],
        loadComponent: () => import('./layouts/dashboard/dashboard.layout').then(c => c.DashboardLayoutComponent),
        children: [
            {
                path: 'ownership/:ownership',
                loadComponent: () => import('./layouts/dashboard/routes/flat-details/flat-details.layout').then(c => c.FlatDetailsLayoutComponent),
                children: [
                    {
                        path: '',
                        loadComponent: () => import('./layouts/dashboard/routes/flat-details/routes/flat-details/flat-details.component').then(c => c.FlatDetailsComponent)
                    },
                    {
                        path: 'payments',
                        loadComponent: () => import('./layouts/dashboard/routes/flat-details/routes/payments/payments.component').then(c => c.FlatPaymentsComponent)
                    }
                ]
            },
            {
                path: '',
                loadComponent: () => import('./layouts/dashboard/routes/flats-list/flats-list.component').then(c => c.FlatsListComponent)
            }
        ]
    },
    {
        path: 'login',
        canActivate: [loggedInGuard],
        loadComponent: () => import('./layouts/auth/auth.layout').then(c => c.AuthLayoutComponent),
    },
    { path: '**', redirectTo: '/login' }
];
